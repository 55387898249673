@import "../../../static/assets/sass/base/variables";

.common-class {
    color: $light-black;
    margin: 0;
    font-weight: 700;
    padding: 10px 15px;
    font-size: 25px;
}

.home {
    .er-container {
        position: relative;
        height: 100%;

        .er-bg {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;

            img {
                width: 230px;
                transform: rotate(-31deg);
            }
        }

        .bottom {
            padding: 3% 6% !important;
            top: 0 !important;

            .er-description {
                margin-top: 0 !important;
                font-size: 30px;
                font-weight: bold;
            }

            .download-section {
                margin-top: 100px;
            }

            .testimonials {
                text-align: center;

                .testimonial-container {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
                    gap: 15px;
                    margin-top: 50px;

                    .testimonial-img {
                        width: 100%;
                    }
                }

                h4 {
                    font-size: 28px;
                    font-weight: bold;
                    color: $white-color;
                    text-align: center;
                    letter-spacing: 0.2px;
                }
            }
        }

        .er-content {
            background: linear-gradient(180deg, #12c55f 0%, #0c6e37 100%);
            background-repeat: no-repeat;
            background-position: center;
            // background: linear-gradient(180deg, #12c55f 0%, #0c6e37 100%);
            // margin: 10px;
            // z-index: 1;
            position: relative;
            padding: 15px 20px 350px 20px;
            letter-spacing: 0.2px;

            .er-title {
                background: #fafafa;
                border-radius: 70px;

                .title {
                    font-weight: bold;
                    font-size: 35px;
                    letter-spacing: 0.67px;
                    text-transform: lowercase;
                    color: $light-black;
                }
            }

            .er-description {
                margin-top: 6%;

                h4 {
                    font-weight: bold;
                    font-size: 28px;
                    text-align: center;
                    letter-spacing: 0.2px;
                    color: $white-color;
                }

                span {
                    font-weight: bolder;
                }
            }

            .er-description-small {
                font-size: 18px;
                line-height: 38px;
                text-align: center;
                font-weight: 400;
                color: $white-color;
                margin-top: 1%;
            }

            .reset-buttons {
                button {
                    border-radius: 10px !important;
                }
            }

            .app-buttons {
                margin: 10px;

                button {
                    padding: 10px 50px;
                    border-radius: 30px;
                    margin: 10px 20px;
                    font-weight: bold;
                    font-size: 18px;
                    border: 1px solid $white-color;
                }

                .play-store {
                    background: $white-color;
                    color: $primary-color;
                }

                .app-store {
                    background: transparent;
                    color: $white-color;
                    // &:hover,
                    &:active {
                        background: $white-color;
                        color: $primary-color;
                    }
                }
            }
        }
        .show-case-img {
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translate(-50%, -50%);

            // img {
            //     // width: 600px;
            //     margin: 0 30px;
            // }

            .left-img {
                // margin-top: 3%;
                margin-right: -15%;
                width: 220px;
            }

            .right-img {
                width: 235px;
                margin-bottom: 20%;
            }
        }
    }

    .er-app-section {
        background: $white-color;
        // height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 10%;
        width: 100%;

        .hr-line {
            border-top: 2px solid #e4e4e4;
            width: 45%;
            margin: 3% 0;
        }

        .how-it-works {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: $light-black;
            width: 100%;

            h4 {
                font-weight: bold;
                font-size: 28px;
            }
            .app-content.mobile {
                display: none;
            }
            .app-content {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-top: 3%;

                img {
                    height: 450px;
                    padding: 0 10%;
                }

                .app-content__description {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    span {
                        color: $primary-color;
                        font-weight: 700;
                        margin-bottom: 10px;
                        font-size: 15px;
                    }
                    h5 {
                        font-weight: bold;
                        font-size: 20px;
                        padding: 10px 0;
                    }
                    p {
                        color: #737373;
                        font-size: 13px;
                    }
                }
            }
        }

        .about-us-section {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            padding-top: 5%;
        }

        .about-us-section.mobile {
            display: none;
        }

        .support-us {
            margin-bottom: 5%;

            .about-us__desciption {
                text-align: left;
            }
        }
        .about-us {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            color: $light-black;
            width: 58%;

            h4 {
                font-weight: bold;
                font-size: 28px;
                text-align: center;
            }

            .about-us__desciption {
                padding: 0 10px 0 20px;
                color: #737373;

                .about-points {
                    list-style: none;
                    padding: 0;
                }
            }

            .video-container {
                padding-right: 20px;
                position: relative;
            }

            iframe {
                position: absolute;
                z-index: -1;
                border: 0;
                width: 500px;
                height: 315px;
                left: 0;
            }

            .video-container-overlay {
                background-color: #181818;
                width: 500px;
                height: 315px;
                // border-radius: 20px;

                i {
                    font-size: 50px;
                    color: $white-color;
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .donate-now {
                border-radius: 50px;
                padding: 10px 25px;
                margin-top: 40px !important;
            }
        }
    }

    .footer {
        background: #fff;
        margin-bottom: 0;
        position: inherit;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 2% 0;

        svg {
            margin-right: 15px;
        }

        i {
            margin-right: 10px;
            font-size: large;
        }

        .share-btn {
            border: 1px solid #6b8a4e;
            border-radius: 5px;
            padding: 10px 15px;
            background: transparent;
            color: #6b8a4e;
        }

        .feedback-btn {
            border-radius: 5px;
            padding: 0;
            background: #636b9f;
            color: #fff;
            border: none;
            margin: 0;
        }

        .brand-name {
            color: $light-black;
            text-transform: lowercase;
            margin: 0;
            font-weight: 700;
            padding: 10px 15px;
            font-size: 25px;
        }

        img {
            max-height: 50px;
        }

        p {
            margin: 0;
        }

        section {
            a {
                cursor: pointer;
            }

            a:not(:last-child) {
                margin-right: 20px;
            }
        }

        .privacy {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 300px;
            p {
                // margin-left: 50px;
                text-decoration: underline;
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.1px;
                color: #5c5c5c;
            }
        }
    }
}

.privacy-policy {
    padding: 10% 4% 5% 4%;

    p {
        margin-bottom: 2rem;
    }

    .heading {
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.2px;
        color: #181818;
    }
    .content {
        padding-top: 20px;
        color: #737373;
    }

    .points-content {
        margin-bottom: 2rem;
        p {
            margin: 0 !important;
        }
    }
}
